// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about-me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-css-legend-css-js": () => import("./../../../src/pages/css/legend.css.js" /* webpackChunkName: "component---src-pages-css-legend-css-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legend-js": () => import("./../../../src/pages/legend.js" /* webpackChunkName: "component---src-pages-legend-js" */),
  "component---src-templates-article-feed-article-feed-js": () => import("./../../../src/templates/article-feed/article-feed.js" /* webpackChunkName: "component---src-templates-article-feed-article-feed-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */)
}

